import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				jasonImg: file(relativePath: { eq: "jason-client-meeting-2.jpg" }) {
					publicURL
				}
				schemaLogo: file(relativePath: { eq: "icon.png" }) {
					publicURL
				}
			}
		`
	);

	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"@id": "https://businesscoach.jasoncornes.co.uk",
		name: "Jason Cornes Business and Executive Coach",
		legalName: "Jason Cornes Ltd",
		description:
			"Grow the business you truly want with a business coach in London.",
		url: "https://www.jasoncornes.co.uk",
		priceRange: "$$",
		areaServed: [
			{
				"@type": "City",
				name: "Eastbourne",
				sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
			},
			{
				"@type": "City",
				name: "Brighton",
				sameAs: "https://en.wikipedia.org/wiki/brighton",
			},
			{
				"@type": "City",
				name: "London",
				sameAs: "https://en.wikipedia.org/wiki/london",
			},
		],
		email: "info@jasoncornes.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "1",
		location: {
			"@type": "City",
			name: "London",
			sameAs: "https://en.wikipedia.org/wiki/london",
		},
		foundingLocation: {
			"@type": "City",
			name: "London",
			sameAs: "https://en.wikipedia.org/wiki/london",
		},
		slogan: "Grow the business you truly want",
		telephone: "020 3051 8763",
		address: {
			"@type": "PostalAddress",
			addressLocality: "London",
			addressRegion: "London",
			postalCode: "EC1V 2NX",
			streetAddress: "Kemp House, 152-160, City Road",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "51.527243604774704",
			longitude: "-0.08900685935364813",
		},
		hasMap: "https://g.page/jasoncornesbusinesscoachlondon",
		openingHours: "Mo,Tu,We,Th,Fr,Sa 06:00-17:00",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Monday",
				opens: "06:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "06:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "06:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "06:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "06:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "13:00",
				dayOfWeek: "http://schema.org/Saturday",
				opens: "06:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "Jason Cornes",
			id: "https://www.jasoncornes.co.uk/about#jasoncornes",
			url: "https://www.jasoncornes.co.uk/about",
			description:
				"Jason Cornes is a Business Coach in London and founder of Jason Cornes Business and Executive Coaching",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "4.9",
			reviewCount: "47",
			url: "https://www.jasoncornes.co.uk/written-testimonials",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.jasoncornes.co.uk/contact-us",
			url: "https://www.jasoncornes.co.uk/contact-us",
			image: `${data.schemaLogo.publicURL}`,
			name: "Jason Cornes",
			description: "Grow the business you truly want",
			telephone: "020 3051 8763",
			contactType: "Business Coach Support",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Eastbourne",
					sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
				},
				{
					"@type": "City",
					name: "London",
					sameAs: "https://en.wikipedia.org/wiki/London",
				},
			],
		},
		logo: `${data.schemaLogo.publicURL}`,
		image: `${data.jasonImg.publicURL}`,
		sameAs: [
			"https://www.facebook.com/jasoncornescoaching",
			"https://www.instagram.com/jasoncornesbusinessmentor",
			"https://www.linkedin.com/in/jason-cornes-65580216",
			"https://twitter.com/JasonCornes",
			"https://www.youtube.com/channel/UC1bTe-FmHjedRYeSQWJngPg",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
