import { Link, useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import { Button } from "react-bootstrap";
import HorizontalTestimonial from "../components/horizontal-testimonial";
import BackgroundImage from "gatsby-background-image";
import CtaBanner from "../components/cta-banner";
import { BiCog } from "react-icons/bi";
import { GiSpade } from "react-icons/gi";
import { BiTime } from "react-icons/bi";
import ReactPlayer from "react-player";
// import {IoAnalytics} from "react-icons/io"
// import {IoMdRocket} from "react-icons/io"
// import {FaBinoculars} from "react-icons/fa"

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			seoImg: file(relativePath: { eq: "1.jpg" }) {
				publicURL
				childImageSharp {
					fluid(quality: 90) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			heroImg: file(relativePath: { eq: "jason-client-meeting-5.jpg" }) {
				publicURL
				childImageSharp {
					fluid(quality: 90) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			executive: file(relativePath: { eq: "4.jpg" }) {
				childImageSharp {
					fluid(quality: 100) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			mark: file(relativePath: { eq: "testimonials/mark-wiffen.jpg" }) {
				childImageSharp {
					gatsbyImageData(quality: 100, width: 400)
				}
			}
			fran: file(relativePath: { eq: "testimonials/lou.jpg" }) {
				childImageSharp {
					gatsbyImageData(quality: 100, width: 400)
				}
			}
			tania: file(relativePath: { eq: "testimonials/Claire freemen New.jpg" }) {
				childImageSharp {
					gatsbyImageData(quality: 100, width: 400)
				}
			}
			nick: file(relativePath: { eq: "testimonials/nick-bryant.jpg" }) {
				childImageSharp {
					gatsbyImageData(quality: 100, width: 400)
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.seoImg.publicURL;
	const executive = data.executive.childImageSharp.fluid;
	const mark = data.mark.childImageSharp.gatsbyImageData;
	const fran = data.fran.childImageSharp.gatsbyImageData;
	const tania = data.tania.childImageSharp.gatsbyImageData;
	const nick = data.nick.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title="Business Coach London UK | Grow the business you truly want"
				description="Grow the business you truly want with a proven Business Coach London. Boost your confidence. Gain focus and get on track."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Business Coach London | Jason Cornes",
					description: "Business Coaching London for the self-employed",
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Jason Cornes Business Coach London",
						},
					],
				}}
			/>
			<section id="hero-homepage">
				<Container fluid>
					<Row className="align-items-lg-center justify-content-between ">
						<Col
							xs={{ span: 12, order: "first" }}
							lg={{ span: 6, order: "first" }}
							xl={{ span: 5, order: "first" }}
							className="px-0 pl-lg-0  mb-4 pe-xl-5 mt-lg-0 order-lg-0"
						>
							<ReactPlayer
								title="Jason Cornes"
								controls
								className="w-100 h-auto"
								url="https://jason-cornes.rjmdigital.net/wp-content/uploads/2023/08/Jason-Square.mp4"
							/>
							{/* <div className="iframe-container-hero mb-4">
								<iframe
									loading="eager"
									className="responsive-iframe-hero"
									src="https://www.youtube.com/embed/mT2OT55F-gk?autoplay=1&mute=1"
									title="Jason Cornes"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div> */}
							{/* <div className=" iframe-container-hero ">
								<ReactPlayer
									className="responsive-iframe-hero w-100 h-100"
									playing
									muted
									controls
									url="https://jason-cornes.rjmdigital.net/wp-content/uploads/2023/06/Jason-Website-Intro-Video.mp4"
								/>
							</div> */}
						</Col>
						<Col
							xs={12}
							lg={6}
							xl={7}
							className="text-left pl-lg-5 pl-xl-5  order-md-0 order-lg-1"
							id="hero-text"
						>
							<p className="text-secondary hind-bold pt-3" id="jc">
								Jason Cornes
							</p>

							<h1 style={{ fontSize: "3rem" }} className="text-primary">
								Business Coach London
							</h1>
							<ul className="py-3">
								<li className="text-primary benefits">
									Grow the business you truly want
								</li>
								<li className="text-primary benefits">Boost your confidence</li>
								<li className="text-primary benefits">
									Gain focus and get on track
								</li>
							</ul>
							<p className="text-primary benefits">
								With Jason Cornes, business &{" "}
								<Link to="/executive-coaching">executive coaching</Link>{" "}
								programmes.
							</p>
							<Button
								className="cta-btn mt-3 cta-btn-gtm"
								variant="secondary"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Book a discovery meeting<span>{` `}</span> &#8594;
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section id="introduction" className="pt-5">
				<Container>
					<Row className="text-left text-md-center">
						<Col>
							<h3 id="years-in-business">
								With nearly 40 years’ experience in business with clients in the
								UK and USA, I work with ‘single decision maker’ ambitious
								business or practice owners, who are feeling lonely in business
								and stuck. I’ve found that most fall into one, or more of these
								categories:
							</h3>
						</Col>
					</Row>
					<Row className="text-center py-5">
						<Col xs={12} md={4} className="pt-4 px-5">
							<BiCog className="text-primary icon" />
							<h3 className="text-tertiary">Proactive</h3>
							<p>Some are proactive and take action to make things happen.</p>
						</Col>
						<Col xs={12} md={4} className="pt-4 px-5">
							<GiSpade className="text-primary icon" />
							<h3 className="text-tertiary">Stuck</h3>
							<p>Others feel stuck and watch things happen around them.</p>
						</Col>
						<Col xs={12} md={4} className="pt-4 px-5">
							<BiTime className="text-primary icon" />
							<h3 className="text-tertiary">Reactive</h3>
							<p>
								The rest are busy reacting to the things that happen to them.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-right mb-4 my-xl-1 ml-xl-3 service-page-body-image"
								src="../images/11.jpg"
								quality={100}
								alt="Jason Cornes Business Coach London"
							/>
							<p>
								Regardless of which category you fall under, one thing I expect
								is true - you want to spend more time working ON your business
								rather than IN it and have more money and time to do the things
								you enjoy with those who matter to you most.
							</p>
							<p>
								Let me ask you a question: If you woke up tomorrow able to tap
								into your full potential and make things happen, what changes
								would you make instantly to your business and your life?
							</p>
							<p>
								Whether it’s scaling your business, or simply giving you the
								freedom to do more of the things you love doing, with the people
								you love most, I can help you overcome the obstacles that are
								preventing you from taking action and living your dream life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="bg-white py-5">
				<Container>
					<Row className="g-5">
						<Col lg={6}>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/WAwwhJQUr-U"
									title="Testimonial | Jason Cornes"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>
						<Col lg={6}>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/3YNLOv1N2BE?si=S9yjmywc36Bpyhw7"
									title="Testimonial Two | Jason Cornes"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">So what’s holding you back right now?</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<ul className="pl-4">
								<li>
									Do you feel stuck and need help carving a new vision for your
									business?
								</li>
								<li>Have you had some success, but crave more?</li>
								<li>Would you like someone to suggest a different approach?</li>
								<li>
									Do you lack focus, or are you being held back by limiting
									beliefs and false assumptions?
								</li>
								<li>
									Are you becoming a “busy fool” - pulled in a million
									directions, but not moving forward in the direction YOU want
									to go?
								</li>
								<li>
									Are you beleaguered by the stresses of everyday life, family
									and running a business? Maybe it’s time to start saying ‘no’
									to things that aren’t moving you forward.
								</li>
							</ul>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								If this sounds like you, working with a business coach could be
								the silver bullet you need to get back on track and move
								forward.
							</p>
							<p>
								Read on to discover how my business coaching can give you the
								edge you need or book a discovery call so we can get to know one
								another and work together to achieve your business goals.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="py-5">
				<Container>
					<Row className="pb-5">
						<Col>
							<h2 className="text-primary">
								How can a business coach in London help you?
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Louise McIntyre. Printingprogress Ltd"
						review="Jason is a breath of fresh air and an incredibly talented business coach. If you are looking for someone to inspire you and help you to see how you can grow both your self-confidence and business then look no further. I have had many business coaches that work to a model that has never quite suited me or my business, but Jason is different he adapts his expertise and meets you exactly where he makes the most impact. Highly Recommended."
						linkedIn="https://www.linkedin.com/in/lou-mcintosh-712ab920"
						profilePic={fran}
					/>

					<HorizontalTestimonial
						name="Mark Wiffen. Marlin Architectural Design Ltd"
						review="I have been working with Jason since the beginning of 2021 and in that time have started outsourcing certain areas of my work as a direct result of Jason's encouragement and guidance. This is now enabling me to bring forward my life-long plan of reducing my working week to 4 days to the beginning of 2022, whereas previously it would have been April 2023. Jason has a knack of subtly pushing you further than you feel you want to go. This is enabling me to drive the business forward taking on more work to improve profits at the same time as reducing my hours. Anyone needing business advice would benefit to the better for their sessions with Jason."
						linkedIn="https://www.linkedin.com/in/mark-wiffen-3244a427/"
						profilePic={mark}
					/>

					<HorizontalTestimonial
						name="Claire Freeman. Consultant Podiatric Surgeon FRCPodS"
						review="How do you choose a business coach? When you know you are stuck, and need help, how do you know who to trust with something as personal and critical as your business? I did a lot of searching and spoke to many different coaches, but when I spoke to Jason I immediately recognised his skill and professionalism. His background, experience and positive attitude have helped me in ways I did not expect. I've learnt so much about myself, the way I run my business and how to effect change. Would I choose him again - absolutely, would I recommend him - absolutely, is my business better off - absolutely, am I still stuck...no."
						profilePic={tania}
						linkedIn="https://www.linkedin.com/in/claire-freeman-frcpods-consultant/"
					/>

					<Row className="g-5">
						<Col lg={6}>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/sp2-RfrN7jU"
									title="Testimonial Two | Jason Cornes"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>

						<Col lg={6}>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/r4bKsNpKzqE"
									title="Testimonial Three | Jason Cornes"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-secondary"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/written-testimonials"
							>
								Read more testimonials
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<p>
								"The best investment you can make is in yourself, the more you
								learn, the more you earn." Wise words, not mine, but those of
								Warren Buffett.
							</p>
							<p>
								And it’s true - when you invest in your personal growth as a
								business leader, it will impact your bottom line.
							</p>
							<p>
								<strong>Business coaching</strong> can give you a whole new set
								of ideas, action points and goals. But above all, it helps you
								to:
							</p>

							<ul>
								<li>
									Reveal the things you miss, those blind spots that are holding
									you back.
								</li>
								<li>
									Overcome assumptions and self-limiting beliefs that negatively
									affect your confidence.
								</li>
								<li>
									Share your concerns and worries with a neutral, non-judgmental
									sounding board.
								</li>
								<li>
									Get unstuck by developing an action plan to propel you
									forward.
								</li>
								<li>
									Become a more effective, decisive leader and a shining example
									to others.
								</li>
								<li>
									Improve your business skills and increase profitability.
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="pb-5">
				<Container>
					<Row>
						<Col>
							<iframe
								loading="lazy"
								width="100%"
								height="500"
								src="https://www.youtube.com/embed/10-i8h0ZnYE"
								title="Business Coach London | Jason Cornes"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</Col>
					</Row>
				</Container>
			</section> */}
			<BackgroundImage
				style={{ backgroundPosition: "center" }}
				className="position-relative"
				tag="section"
				fluid={executive}
				alt="Jason Cornes delivering a Business Coaching session in London"
			>
				<div
					style={{ background: "black", opacity: 0.3, zIndex: 1 }}
					className="position-absolute w-100 h-100 start-0"
				></div>
				<Container
					style={{ zIndex: 2 }}
					className="py-5 py-lg-8 position-relative"
				>
					<Row className="text-center">
						<Col>
							<h3 className="text-white pb-3">
								Looking for Self-Employed Business Mentoring?
							</h3>
							<Button
								variant="secondary"
								className="cta-btn cta-btn-gtm"
								as={Link}
								size="lg"
								to="/business-mentoring"
							>
								Find out More
							</Button>
						</Col>
					</Row>
				</Container>
			</BackgroundImage>

			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What’s it like working with me?</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								My business coaching focuses on the self-employed and single
								director businesses with or without staff. Any business owners
								ranging from lawyers, attorneys, medical professionals such as
								surgeons, osteopaths, general practitioners, accountants, and
								teaching professionals through to creatives and other
								professional service providers.
							</p>
							<p>
								As a business and
								<Link to="/executive-coaching"> executive coach in London</Link>
								, I'm here to help and support ‘single decision-making’ business
								leaders in any professional or creative services small business.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What coaching isn't about</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								A one-size-fits-all business and{" "}
								<Link to="/executive-coaching">executive coaching</Link>{" "}
								approach won’t deliver the results you need. You’re unique and
								so are your challenges and circumstances, so your coaching will
								be <strong>tailor-made for you</strong>.
							</p>
							<p>
								I’m not a consultant. I won’t tell you what to do. I offer a
								working,{" "}
								<Link to="/executive-coaching">executive coaching</Link>{" "}
								relationship between the two of us, where you make the decisions
								with my help, support, guidance and suggestions.
							</p>

							<p>
								However, I will hold you accountable. We’ll agree on things to
								do at the end of each coaching session. My role isn’t to run
								your business. Instead, I’ll help you set and{" "}
								<strong>achieve your business goals;</strong> guiding you along
								the way as your{" "}
								<Link to="/business-mentoring">business mentor</Link> and coach.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Confidential coaching</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								I have experienced the highs and lows of business life
								first-hand, and I know how tough and lonely it can be at the
								top. So, it goes without saying that all of my coaching work is
								on a one-to-one basis, is 100% confidential and totally and
								utterly non-judgmental.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 bg-tertiary text-white">
				<Container>
					<Row>
						<Col>
							<h2>Learn more about coaching</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								I meet my clients regularly via Zoom. In this way I’m absolutely
								convinced that, together, I can help you to grow in confidence
								with the increased leadership and business skills you need to
								grow your small business and increase your profits.
							</p>

							<p>
								To learn more about how business and{" "}
								<Link className="text-white" to="/executive-coaching">
									executive coaching
								</Link>{" "}
								can help you to revolutionize and transform your business or
								career over the next year click below to book a free,
								no-obligation discovery call with me.
							</p>
							<p>
								Explore my testimonials to hear what my clients say about their
								coaching experience with me.
							</p>
						</Col>
					</Row>
					<Row className="text-center">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								as={Link}
								size="lg"
								to="/written-testimonials"
							>
								Read more testimonials
							</Button>
						</Col>
					</Row>
				</Container>
			</section>

			<CtaBanner
				headline="Ready to Book a discovery meeting?"
				btnTxt="Get in Touch"
				btnLink="/contact-us"
				bgColor="light"
			/>
			<section className="py-5">
				<Container>
					{/* <Row>
            <Col>
              <h2>My Coaching and Mentoring Services</h2>
                          </Col>
          </Row> */}
					<Row>
						{/* <Col className="text-center mb-4" xs={12} md={4}>
              <StaticImage
                src="../images/jason-client-meeting-3.jpg"
                style={serviceimg}
                quality={100}
              />
              <h3 className="py-3 cta-btn-gtm">Business Coaching</h3>
              <Button variant="outline-secondary" as={Link} to="/">
                Find Out More
              </Button>
            </Col> */}

						<Col className="text-center mb-4" xs={12} md={6} lg={4}>
							<StaticImage
								src="../images/1.jpg"
								style={serviceimg}
								quality={100}
								alt="Jason Cornes in a client meeting delivering a Business Coach London session"
							/>
							<h3 className="py-3">Packages and Pricing</h3>
							<Button
								className="cta-btn-gtm"
								variant="outline-secondary"
								as={Link}
								to="/packages"
							>
								Find Out More
							</Button>
						</Col>
						<Col className="text-center mb-4" xs={12} md={6} lg={4}>
							<StaticImage
								src="../images/14.jpg"
								style={serviceimg}
								quality={100}
							/>
							<h3 className="py-3">About Jason</h3>
							<Button
								className="cta-btn-gtm"
								variant="outline-secondary"
								as={Link}
								to="/about"
							>
								Find Out More
							</Button>
						</Col>
						<Col
							className="text-center mb-4 mt-md-4 mt-lg-0"
							xs={12}
							md={{ span: 6, offset: 3 }}
							lg={{ span: 4, offset: 0 }}
						>
							<StaticImage
								src="../images/2.jpg"
								style={serviceimg}
								quality={100}
								alt="Business Coach London session with Jason Cornes"
							/>
							<h3 className="py-3 px-md-4 px-lg-0">
								Frequently Asked Questions
							</h3>
							<Button
								className="cta-btn-gtm"
								variant="outline-secondary"
								as={Link}
								to="/frequently-asked-questions"
							>
								Find Out More
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

const serviceimg = {
	minHeight: "290px",
};

export default IndexPage;
